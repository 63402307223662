import './Contact_sec.css';
import Header from '../Header/header.jsx';
import Footers from '../Footer/footer.jsx';
function Contact_sec() {
  return (
   <>
   <div className="App">
<Header/>
    <section className="h_wrapper pd_bt">
      <div className="paddings innerWidth flexCenter hero-container">
        {/* Left side start */}
        <div className='Left_section flexColStart'>
          <div className='up_section'>
            <div className='h1_up_contact'>-Contact Me</div>
            <div className='heading_section'>Get In Touch With Me</div>
            <div className='para_section_cont'>The technological revolution is changing aspect of our lives, and the fabric of society itself. it's also changing the way we learn and what we learn. Factual knowledge is less prized when everything you ever need to know can be found on your phone. There's no imperative to be an expert Factual knowledge is less prized when everything you ever need to know can be found at doing everything.</div>
          </div>
<div className='phonesss'>
          <div className='up_contact_section'>
            <div className='phone_icon'>
                   <img src="phone-call.png" alt="" width={34}/>
            </div>

            <div className="phone1">
              <div className="heading_phone">Phone</div>
              <div className="number1_contact">+99 (0) 101 0000 888</div>
              <div className="number2_contact">+99 (0) 101 0000 777</div>
            </div>
          </div>
          <div className='up2_contact_section'>
            <div className='phone_icon'>
            <img src="email_2.png" alt="" width={34}/>

            </div>

            <div className="phone1">
              <div className="heading_phone">Email</div>
              <div className="number1_contact">Info@yourdomain.com</div>
              <div className="number2_contact">Info@yourdomain.com</div>
            </div>
          </div>
        </div>
    </div>    
        {/* Left side end */}

        {/* Right side start */}
        <div className='Right_section'>
             <div className='container_input'>
              <div className='first_last_name'>
                 <input type="text" className='nameFirst' placeholder='FirstName'/>
                 <input type="text" className='nameLast' placeholder='LastName'/>
              </div>
            <div className='text_area'>
            <textarea id="myTextarea" cols={83} rows={13} placeholder='Message'></textarea>
            </div>
            <button className="btn2 bt_contact_block btn_contact_me">
                Contact
              </button>
             </div>
         </div>
        {/* Right side end */}
      </div>
    </section>
    <Footers/>
    </div>
    </>
  );
}

export default Contact_sec;
