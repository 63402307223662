import './footer.css';


function footer() {
  return (
   <footer className='footer'>
        <div className='f00t_pd'>
        <div className="innerWidth flexCenter footer-container">
          <div className="img_c paddings first_container ">
           <img src='https://demo.phlox.pro/freelancer/wp-content/uploads/sites/272/2021/02/logo-header.svg' alt='logo' width={100} />
           </div>
           <div className='second_container'>
           © 2023 Freelancer - Phlox Elementor WordPress Theme. All rights reserved.
           </div>
        </div>
        <div className='con'>
        <div className="flexCenter flexColStart bd">
           <img src='gt.png' alt='logo' width={25}/>
        </div>
        <div className="flexCenter flexColStart bd">
           <img src='gt.png' alt='logo' width={25}/>
        </div>
        <div className="flexCenter flexColStart bd">
           <img src='gt.png' alt='logo' width={25}/>
        </div>
        <div className="flexCenter flexColStart bd">
           <img src='gt.png' alt='logo' width={25}/>
        </div>
        </div>

        </div>
   </footer>
  );
}

export default footer;
