import React from 'react';
import './State.css';
import CountUp from 'react-countup'


function State() {
  return (
    <div className="flexCenter stats">
    <div className="flexColCenter stat stat1">
        <span className='count_font'>
            <CountUp start={7} end={56} duration={5}/>

        </span>
        <span className='secondary_Text2'>Happy Clients</span>
    </div>
    <div className="flexColCenter stat stat2">
        <span className='count_font'>
            <CountUp start={30} end={86} duration={5}/>

        </span>
        <span className='secondary_Text2'>Projects Done</span>
    </div>
    <div className="flexColCenter stat stat3">
        <span className='count_font'>
            <CountUp start={2} end={16} duration={5} />
        </span>
        <span className='secondary_Text2'>Award Winning</span>
    </div>
    <div className="flexColCenter stat stat4">
        <span className='count_font'>
            <CountUp start={1} end={7} duration={5} />
            
        </span>
        <span className='secondary_Text2'>Years Experience</span>
    </div>
    </div>   
  );
}

export default State;
