import './Work_con.css';



function Work() {
  return (
    <section className='full_container_work'>
        <center>
    <div className="H_Work_togather ">
        <center>
      <div className="paddings innerWidth hero-container_work cent_secree">
      <hr />

        <div className='work_heading'>Lets Work Together</div>
        <div className='work_para'>The technological revolution is changing aspect of our lives, and the fabric of society itself. it's also changing the way we learn and what we learn</div>
        <button className='btn2 btn btn4 btn5'>Contact Me</button>
      </div>
      </center>
    </div>
    </center>
    </section>
  );
}

export default Work;
