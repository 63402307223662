import React from 'react';
import './skill.css';

function Intro() {
  return (
    <section className="h_wrapper_skill">
       <div className="paddings innerWidth flexCenter hero-container reve_direction">
       {/*Lift side start*/}
       <div className='Left_section_skill flexColStart'>
          {/*top start*/}
          <div className="top_skill flexCenter">
             <div className="left_box_top">
                <div className='cir_icon'>
                <img src='./p_design.png' alt='logo' width={40} />
                </div>
                <div className="heading_left_box">
                  Product Design
                </div>
                <div className="par_left_box">
               <center> The technological revolution is changing aspect</center>
                </div>
                
             </div>
             <div className="Right_box_top ">
             <div className='cir_icon'>
                <img src='./p_design.png' alt='logo' width={40} />
                </div>
                <div className="heading_left_box">
                  Logo Design
                </div>
                <div className="par_left_box">
               <center> The technological revolution is changing aspect</center>
                </div>
             </div>
          </div>
          {/*bottom start*/}
          <div className="bottom_skill flexCenter">
          <div className="left_box_top ui_marg">
                <div className='cir_icon'>
                <img src='./p_design.png' alt='logo' width={40} />
                </div>
                <div className="heading_left_box">
                  UI Design
                </div>
                <div className="par_left_box">
               <center> The technological revolution is changing aspect</center>
                </div>
                
             </div>
             <div className="Right_box_top">
             <div className='cir_icon'>
                <img src='./p_design.png' alt='logo' width={40} />
                </div>
                <div className="heading_left_box">
                  Icon Design
                </div>
                <div className="par_left_box">
               <center> The technological revolution is changing aspect</center>
                </div>
             </div>
          </div>
       </div>
       {/*Lift side end*/}
       {/*Right side start*/}
       <div className='Right_section_skill'>
          <div className='h1_skill'>-My Skill</div>
          <div className='heading_skill'>Why Hire Me For <br />Next Project?</div>
          <div className='para_skill'>The technological revolution is changing <br />aspect of our lives, and the fabric of society <br /> itself. it's also changing the way we learn and <br />what we learn. Factual knowledge is less prized <br />when everything you ever need to know can <br />be found on your phone. There's no imperative <br />to be an expert at doing everything when you <br />can.</div>
          <button className='btn2 btn'>Download CV</button>

       </div>
      {/*Right side end*/}

       </div>
    </section>
    
  );
}

export default Intro;
