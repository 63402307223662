import React, { useState, useEffect } from 'react';
import './Circle.css';

function Circle_gree() {

  return (
    <section>
      <div className="cir_wrapper flexColEnd2">
        <div></div>
      </div>
    </section>
  );
}

export default Circle_gree;


