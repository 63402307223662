import React from 'react';
import './Intro.css';
import Contact_sec from '../Contact_page/Contact_sec';

function Intro() {
  return (
    <section className="h_wrapper">
       <div className="paddings innerWidth flexCenter hero-container">
       {/*Lift side start*/}
       <div className='Left_section flexColStart'>

       <div className='h1_intro '>-Introduction</div>
       <div className='h1_large_intro innerWidth '>Hello <br/>I'm Jessy <br/>Walter</div>
       <div className='p_intro innerWidth '>Since beginning my journey as a freelance designer <br />nearby 7 years ago, I 've done remote work for <br />agencies, consulted for startup, and collaborated with <br />talented people to create digital products.</div>
       <a href="/Contact_sec"><button className='btn2 btn'>Contact Me</button></a>
       </div>

       {/*Right side start*/}
       <div className='Right_section flexCenter'>
       <div className='image_container'>
       <img src='https://demo.phlox.pro/freelancer/wp-content/uploads/sites/272/2021/02/hero-img.png' alt='logo' />
      
       </div>
       <div className='circle'>curcl</div>

       </div>
      {/*Right side end*/}

       </div>
    </section>
    
  );
}

export default Intro;
